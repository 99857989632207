import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components"
import { StaticQuery, graphql } from 'gatsby'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { ContextProviderComponent } from "./context"

import ContextProvider from '../provider/contextProvider'

import GlobalStyle from '../common/global'
import media from '../common/mediaQuery'

// import Header from './header'
import Footer from './footer'
import MiniCart from './minicart'
import Header from './header'
// import MiniCart from './minicart'

if (typeof window !== undefined) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

class Layout extends Component {
  state = {	
    navOpen: false	
  }	

  anchorLinks = (e) => {
    const target = e.target.dataset.name;
    document.getElementById(target).scrollIntoView({ 
      behavior: 'smooth'
    });
  }

  toggleNavigation = (e) => {	
    const target = e.target;

    this.setState(prevState => ({	
      navOpen: !prevState.navOpen	
    }));

    this.state.navOpen ? target.innerHTML = 'Menu' : target.innerHTML = 'Close'	
  }
  
  render() {
    const { children } = this.props;

    return (
      <ContextProviderComponent>
        <ContextProvider>
          <GlobalStyle />
          <Header anchors={ this.anchorLinks } />
          <FlexWrapper>
            {children}
          </FlexWrapper>
          {/* <StaticQuery
            render={data => (
              <>
              </>
            )}
          /> */}
          <Footer />
          <MiniCart />
        </ContextProvider>
      </ContextProviderComponent>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const FlexWrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100vh;

  ${media.tablet`
    min-height: 70vh;
  `}
`;
