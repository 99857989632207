import styled, { css } from 'styled-components'
import media from './mediaQuery'

export const Title = styled.h1`
  font-family: "Grotesk", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.7rem;
  margin: 0;

  ${media.tablet`
    font-size: 4.175rem;
    letter-spacing: -0.17rem;
    line-height: 4.15rem;
  `}

  ${props => props.bold && css`
    font-family: "GroteskBold", sans-serif;
  `}

  ${props => props.static && css`
    font-size: 4.175rem;
    line-height: 4.15rem;
  
    ${media.tablet`
      font-size: 4.175rem;
      line-height: 4.15rem;
    `}
  `}
`;

export const Type = styled.p`
  font-family: "Grotesk", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: -0.045rem;
  margin: 0;

  ${media.tablet`
    line-height: 1.5rem;
  `}

  ${props => props.disabled && css`
    opacity: 0.5;

    a {
      pointer-events: none;
    }
  `}

  ${props => props.hasLink && css`
    cursor: pointer;
  `}

  ${props => props.bold && css`
    font-family: "GroteskBold", sans-serif;
  `}

  ${props => props.capitalized && css`
    text-transform: capitalize
  `}
`;
