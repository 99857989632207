import React, { useContext } from 'react'
import reduce from 'lodash/reduce'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ContextConsumer from './context'
import { theme } from '../common/global'
import media from '../common/mediaQuery'
import { Type } from '../common/type'

import StoreContext from '../context/StoreContext'

const useQuantity = () => {
  const { store: {checkout} } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Header = ({ data, value, toggle, status, anchors }) => {

  const [hasItems, quantity] = useQuantity()

  return (
    <ContextConsumer>
      {({ contextData, set }) => (
        <Nav cartOpen={contextData.cartOpen} isInverted={process.browser && localStorage.getItem('page_color')}>
          <Inner>
            <Logo>
              <Link to={`/`}>
                <Type bold>elkin editions</Type>
              </Link>
              <Type onClick={() => set({ navOpen: !contextData.navOpen })} as="span">{contextData.navOpen ? '-' : '+'}</Type>
            </Logo>
            <Menu navOpen={contextData.navOpen}>
              <li>
                <AnchorLink to={`/#featured`}>
                  <Type>featured</Type>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to={'/#info'}>
                  <Type>info</Type>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to={'/#index'}>
                  <Type>index</Type>
                </AnchorLink>
              </li>
              <li>
                <Type disabled>store</Type>
              </li>
            </Menu>
            {hasItems && 
              <CartButton onClick={() => set({ cartOpen: !contextData.cartOpen })}>
                <Count>{quantity}</Count>
              </CartButton>
            }
            <ToggleButton onClick={() => set({ invert: !contextData.invert })}></ToggleButton>
          </Inner>
        </Nav>
      )}
    </ContextConsumer>
  );
}

const Nav = styled.nav`
  align-items: center;
  display: flex;
  background-color: ${theme.colors.black};
  height: 60px;
  padding: 0 25px;
  position: fixed;
  top: 0;
  transition: all ${theme.speed.fast} ease-in-out;
  z-index: ${theme.index.z8};
  width: 100%;

  ${media.tabletLarge`
    height: 60px;
    padding: 0 30px;
  `}

  a {
    line-height: 1.96rem;
  }

  ${props => props.isInverted === 'true' && css`
    background-color: ${theme.colors.white};

    li, a {
      color: ${theme.colors.black};
    }

    ${Menu} {
      background-color: ${theme.colors.white};
    }

    ${Logo} {
      p, span {
        color: ${theme.colors.black};
      }
    }

    ${CartButton} {
      color: ${theme.colors.black};
    }

    ${ToggleButton} {
      background-color: ${theme.colors.black};
    }
  `}

  ${props => props.cartOpen && css`
    background-color: ${theme.colors.yellow};

    li, a {
      color: ${theme.colors.yellow};
      pointer-events: none;
    }

    ${Menu} {
      background-color: ${theme.colors.yellow};
    }

    ${Logo} {
      p {
        color: ${theme.colors.black};
      }

      span {
        display: none;
      }
    }

    ${CartButton} {
      background-color: ${theme.colors.black};
      color: ${theme.colors.white};
    }

    ${ToggleButton} {
      background-color: ${theme.colors.yellow};
      pointer-events: none;
    }
  `}
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Logo = styled.div`
  cursor: pointer;
  opacity: 1;
  position: relative;
  width: fit-content;
  z-index: 200;

  ${media.tabletLarge`
    cursor: default;
    flex: 0 0 27.8%;
    padding-right: 20px;
    width: 100%;
  `}

  span {
    bottom: 0;
    margin-left: 10px;
    position: absolute;
    right: -30px;
    top: 4px;
    
    ${media.tabletLarge`
      display: none;
    `}
  }
`;

const Menu = styled.ul`
  background-color: ${theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  left: 0;
  padding: 0 25px 20px;
  position: fixed;
  right: 0;
  top: 60px;
  transform: translateY(-150%);
  transition: background-color .2s ease-in-out, transform ${theme.speed.fast} ease-in-out;
  width: 100%;

  ${media.tabletLarge`
    flex: 0 0 71.7%;
    left: 1px;
    margin-top: 0;
    padding: 0;
    position: relative;
    top: 0;
    transform: translateY(0);
  `}

  li {
    display: block;
    width: 100%;

    ${media.tabletLarge`
      width: fit-content;
      
      &:first-of-type {
        width: calc(13.5% + 2px);
      }

      &:nth-of-type(2) {
        width: calc(36% + 8px);
      }

      &:nth-of-type(3) {
        width: calc(13.5% + 2px);
      }
    `}

    p {
      line-height: 1.5rem;
    }
  }

  ${props => props.navOpen && css`
    transform: translateY(0);
  `}
`;

const CartButton = styled.button`
  cursor: pointer;
  background-color: ${theme.colors.yellow};
  border-radius: 100px;
  right: 55px;
  opacity: 1;
  position: absolute;
  height: 18px;
  transition: opacity .2s ease-in-out;
  width: 18px;

  &:hover {
    opacity: 0.6;
  }
`;

const Count = styled.p`
  font-family: "GroteskBold", sans-serif;
  margin: 0;
  position: relative;
`;

const ToggleButton = styled.button`
  cursor: pointer;
  background-color: ${theme.colors.white};
  border-radius: 100px;
  height: 18px;
  opacity: 1;
  padding: 9px;
  position: absolute;
  right: 25px;
  transition: opacity .2s ease-in-out;
  width: 18px;

  &:hover {
    opacity: 0.6;
  }

  ${media.tabletLarge`
    right: 30px;
  `}
`;

export default Header;
