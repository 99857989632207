import { createGlobalStyle } from 'styled-components';

export const theme = {
  colors: {
    black: '#1A1A1A',
    white: '#F2F2F2',
    red: '#FF0000',
    navy: 'rgba(6, 24, 150, 1)',
    yellow: '#FFE600',
    lightYellow: 'rgba(251, 231, 77, 0.6)',
    selectiveYellow: '#FFB100',
    persianRose: '#FF2EA0'
    // rainbow: linear-gradient(
    //   '105deg', 
    //   '#FFF2F2', 
    //   '#C5EDEB', 
    //   '#EDC5E0',
    //   '#D9FF52'
    // )
  },
  breakpoints: {
    mobile: '425px',
    tablet: '768px',
    tabletLarge: '1024px',
    desktop: '1280px'
  },
  sizes: {
    desktop: 1280,
    tabletLarge: 1024,
    tablet: 768,
    tabletPlus: 769,
    phone: 425
  },
  index: {
    z1: 100,
    z2: 200,
    z3: 300,
    z4: 400,
    z5: 500,
    z6: 600,
    z7: 700,
    z8: 800,
    z9: 900,
    z10: 1000,
    zmax: 999999,
  },
  speed: {
    fast: '.2s',
    med: '.5s',
    slow: '.8s',
    xSlow: '.9s',
  }
};


const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Grotesk';
    font-weight: normal;
    src: url('../../static/fonts/MonumentGrotesk-Regular.woff') format('woff'),
         url('../../static/fonts/MonumentGrotesk-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'GroteskMedium';
    font-weight: normal;
    src: url('../../static/fonts/MonumentGrotesk-Medium.woff') format('woff'),
         url('../../static/fonts/MonumentGrotesk-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'GroteskBold';
    font-weight: normal;
    src: url('../../static/fonts/MonumentGrotesk-Bold.woff') format('woff'),
         url('../../static/fonts/MonumentGrotesk-Bold.woff2') format('woff2');
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    height: 100%;
  }

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  *:focus {
    outline: none;
  }
  
  body {
    background: ${theme.colors.black};
    color: ${theme.colors.white};
    font-size: 16px;
    font-variant-ligatures: common-ligatures;
    -moz-font-feature-settings: 'liga', 'clig';
    -webkit-font-feature-settings: 'liga', 'clig';
    font-feature-settings: 'liga' 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 26px;
    margin: 0 auto;
    min-height: 100%;
    scroll-behavior: smooth;
    padding: 60px 0 0;
    position: relative;
    width: 100%;
  }

  main {
    scroll-behavior: smooth;
    width: 100%;
  }
  
  a {
    cursor: pointer;
    color: ${theme.colors.white};
    font-size: 1rem;
    line-height: 2.16rem;
    margin: 0;
    position: relative;
    text-decoration: none;

    img, p {
      opacity: 1;
      transition: opacity .2s ease-in-out;
      width: fit-content;
    }

    &:hover {
      
      img {
        opacity: 0.6;
      }

      p {
        opacity: 0.6;
      }
    }
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    transition: color $med ease-in-out;
  }

  input {
    border-radius: 0;
    -webkit-appearance: none;
    
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${theme.colors.green};
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${theme.colors.green};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: ${theme.colors.green};
    }

    &[type=email] {
      width: 100%;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 1rem;
    font-weight: 400;
    list-style-type: none;
    margin: 0;
  }

  select {
    background: transparent;
  }

  .tl-edges {
    overflow-x: visible;
  }

  input[type=email] {
    -webkit-appearance: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
  }

  @keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }

  @-webkit-keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme.colors.bone};
    -webkit-box-shadow: inset 0 0 0px 9999px transparent;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export default GlobalStyle;
