import React from 'react'
import styled, { css } from 'styled-components'
import ContextConsumer from './context'
import { theme } from '../common/global'
import media from '../common/mediaQuery'
import { Type } from '../common/type'

const Footer = ({ data, value }) => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <ContextConsumer>
      {({ contextData }) => (
        <FooterWrapper isInverted={process.browser && localStorage.getItem('page_color')}>
          <Item>
            <Type>©{year} elkin editions</Type>
          </Item>
          <Item>
            <a href="mailto:hello@elkineditions.com">
              <Type>hello@elkineditions.com ↗</Type>
            </a>
          </Item>
        </FooterWrapper>
      )}
    </ContextConsumer>
  );
}

export default Footer;

const FooterWrapper = styled.div`
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  order: 3;
  padding: 90px 25px;
  transition: all ${theme.speed.fast} ease-in-out;
  width: 100%;

  ${media.tablet`
    order: initial;
    padding: 90px 30px 40px calc(28.1% + 10px);
  `}

  ${props => props.isInverted === 'true' && css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  `}
`;

const Item = styled.div`
  display: block;
  width: 100%;

  ${media.tablet`
    flex: 0 0 calc(50% - 17px);
    margin-top: 0;
  `}

  &:last-of-type {

    ${media.tabletLarge`
      padding-left: 20px;
    `}
  }
`;
