// Context.js
import React, { Component } from "react"

export const defaultContextValue = {
  contextData: {
    // set your initial data shape here
    projectActive: false,
    cartOpen: false,
    navOpen: false,
    invert: true,
  },
  set: () => {},
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

class ContextProviderComponent extends Component {
  constructor() {
    super()
    
    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    }
  }
  
  setData(newData) {
    this.setState(state => ({
      contextData: {
        ...state.contextData,
        ...newData,
      },
    }))
    if (process.browser) { localStorage.setItem('page_color', JSON.stringify(this.state.contextData.invert));}
  }
  
  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { Consumer as default, ContextProviderComponent }