import React from 'react'
import styled, { css } from 'styled-components'

import media from '../common/mediaQuery'
import Cart from '../common/cart'
import { theme } from '../common/global'

import ContextConsumer from './context'

const MiniCart = () => (
  <ContextConsumer>
    {({ contextData, set }) => (
      <Wrapper className="minicart" open={contextData.cartOpen}>
        <Close onClick={() => set({ cartOpen: !contextData.cartOpen })}>
          <svg viewBox="0 0 55.71 55.71">
            <polygon className="st0" points="55.71,0.71 55.01,0 27.86,27.15 0.71,0 0,0.71 27.15,27.86 0,55.01 0.71,55.71 27.86,28.56 
            55.01,55.71 55.71,55.01 28.56,27.86 "/>
          </svg>
        </Close>
        <Cart />
      </Wrapper>
    )}
  </ContextConsumer>
)

export default MiniCart

const Wrapper = styled.div`
  background-color: ${theme.colors.yellow};
  bottom: 0;
  left: 0;
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  transition: transform ${theme.speed.med};
  z-index: ${theme.index.z2};

  ${props => props.open && css`
    transform: translateY(0);
  `}

  ${props => props.border && css`
    border-left: 1px solid ${theme.colors.black};
    border-right: 1px solid ${theme.colors.black};
  `}
`;

const Close = styled.div`
  cursor: pointer;
  height: 60px;
  padding: 5px;
  position: absolute;
  right: 55px;
  top: 65px;
  width: 60px;
  transform: rotate(0deg);
  transition: transform .3s ease-in-out;
  z-index: ${theme.index.z1};

  ${media.tablet`
    right: 55px;
  `}

  &:hover {
    transform: rotate(90deg);
  }

  img {
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
