import React, { useContext } from 'react'
import reduce from 'lodash/reduce'
import styled from 'styled-components'

import media from './mediaQuery'
import LineItem from './lineItem'
import { Type } from './type'

import { theme } from '../common/global'

import StoreContext from '../context/StoreContext'

const Cart = () => {
  const {
    store: { checkout },
    
  } = useContext(StoreContext)

  const useQuantity = () => {
    const { store: {checkout} } = useContext(StoreContext)
    const items = checkout ? checkout.lineItems : []
    const total = reduce(items, (acc, item) => acc + item.quantity, 0)
    return [total !== 0, total]
  }

  const handleCheckout = () => {
    window.open(checkout.webUrl,"_self")
  }

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  const [hasItems, quantity] = useQuantity()

  return (
    <Wrapper>
      <Meta>
        <Type bold> cart</Type>
      </Meta>
      {hasItems && 
        <>
          <Products>
            {line_items}
          </Products>
          <Checkout>
            <div>
              <Type>Subtotal:</Type>
              <Type bold as="span">${checkout.subtotalPrice}</Type>
            </div>
            <button onClick={handleCheckout} disabled={checkout.lineItems.length === 0}>checkout</button>
          </Checkout>
        </>
      }
    </Wrapper>
  )
}

export default Cart

const Wrapper = styled.div`
  background-color: ${theme.colors.yellow};
  color: ${theme.colors.black};
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  padding: 70px 25px 25px;
  position: relative;

  ${media.tablet`
    padding: 70px 30px 30px;
  `}
`

const Meta = styled.div`
  width: 100%;

  ${media.tablet`
    padding-right: 10px;
  `}

  ${media.tabletLarge`
    flex: 0 0 26%;
    margin-top: -6px;
    padding-right: 20px;
  `}
`;

const Products = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 66.67%;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;

  ${media.tablet`
    flex: 0 0 60%;
  `}

  ${media.tabletLarge`
    flex: 0 0 63.5%;
    margin-top: 0;
  `}
`;

const Checkout = styled.div`
  padding: 50px 0;
  position: relative;
  width: 100%;

  ${media.tablet`
    align-self: flex-end;
    bottom: 30px;
    flex: 0 0 40%;
    margin-top: 40vh;
    padding: 0 0 0 20px;
    position: sticky;
  `}

  ${media.tabletLarge`
    flex: 0 0 10%;
    margin-top: -6px;
    padding: 0;
  `}

  div {

    ${media.tablet`
      margin-bottom: 100px;
    `}

    ${media.tabletLarge`
      margin-bottom: 200px;
    `}
  }

  button {
    cursor: pointer;
    background-color: ${theme.colors.black};
    border-radius: 6px;
    color: ${theme.colors.white};
    font-family: "GroteskBold", sans-serif;
    font-size: 1.3rem;
    font-weight: 400; 
    height: 40px;
    letter-spacing: 0.5px;
    line-height: 1rem;
    opacity: 1;
    padding: 6px 15px 6px;
    transition: opacity ${theme.speed.med} ease-in-out;
    width: auto;

    ${media.tablet`
      padding: 10px 9px 16px;
    `}

    ${media.tabletLarge`
      bottom: 0;
      left: 0;
      position: absolute;
    `}

    &:hover {
      opacity: .6;
    }
  }
`;
