import React, { useContext } from 'react'
import styled from 'styled-components'

import media from './mediaQuery'
import { Type } from './type'

import StoreContext from '../context/StoreContext'


const LineItem = props => {
  const { line_item } = props
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const variantImage = line_item.variant.image ? (
    <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
    />
  ) : null

  const selectedOptions = line_item.variant.selectedOptions
    ? line_item.variant.selectedOptions.map(
        option => `${option.name}: ${option.value} `
      )
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, line_item.id)
  }

  return (
    <Wrapper>
      <ImageWrapper>
        {variantImage}
      </ImageWrapper>
      <Meta>
        <Type>
          {line_item.title}
          {`  `}
          {line_item.variant.title === !'Default Title'
            ? line_item.variant.title
            : ''}
        </Type>
        <Counter>
          <Type bold>${line_item.variant.price}</Type>
          <Type>{selectedOptions}</Type>
          <Type>Qty: {line_item.quantity}</Type>
          <button onClick={handleRemove}>
            <Type>Remove</Type>
          </button>
        </Counter>
      </Meta>
    </Wrapper>
  )
}

export default LineItem

const Wrapper = styled.li`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  ${media.tablet`
    flex: 0 0 50%;
    padding-right: 20px;
  `}

  ${media.tabletLarge`
    flex: 0 0 33%;
  `}

  /* +li {
    margin-top: 15px;

    ${media.tablet`
      margin-top: 30px;
    `}
  } */
`;

const Meta = styled.div`
  margin-top: 20px;
  position: relative;
  width: 100%;
`;

const Counter = styled.div`
  position: relative;
  margin-top: 20px;

  ${media.tablet`
    margin-top: 30px;
  `}
`;

const ImageWrapper = styled.div`
  display: block;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
